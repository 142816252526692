<template>
  <div id="printable" :class="$style.container">
    <div :class="$style.legend">
      <custom-legend-item v-for="(user, index) in pieData" :key="user.userName" v-model="user.checked"
                          :text="user.userName" :color="getColorByPosition(index)"
      />
    </div>

    <div id="chart1" :class="$style.chart">
      <div :class="$style.title">
        Документов
      </div>
      <apexchart :class="$style.svg" type="pie" width="100%" height="100%" :options="chartOptions"
                 :series="getArray('docs')"
      />
    </div>

    <div id="chart2" :class="$style.chart">
      <div :class="$style.title">
        Страниц
      </div>
      <apexchart :class="$style.svg" width="100%" height="100%" type="pie" :options="chartOptions2"
                 :series="getArray('pages')"
      />
    </div>
  </div>
</template>

<script>
import Apexchart from 'vue-apexcharts';
import { getPrintUserStatistics } from '@/api/methods/reports/printers/statistics/index';
import CustomLegendItem from '@/components/common/pie-chart/custom-legend-item.vue';
import ru from 'apexcharts/dist/locales/ru.json';

export default {
  components: {
    Apexchart,
    CustomLegendItem,
  },

  data() {
    return {
      name: 'Статистика печати по пользователям',
      pieData: [],
      baseColors: [
        '#ffb0b0',
        '#ffcdad',
        '#ffecbc',
        '#d5e7ad',
        '#a0e2af',
        '#7ad6d7',
        '#94c2e0',
        '#d3b6e3',
      ],
    };
  },
  computed: {
    dateRange() {
      return this.$store.getters['filters/formattedDateRange'];
    },
    series() {
      const filteredData = [];
      /* eslint-disable-next-line */
      for (const [index, item] of this.pieData.entries()) {
        const newItem = {};
        newItem.userName = item.userName;
        if (item.checked === true) {
          newItem.docs = item.docs;
          newItem.pages = item.pages;
        } else {
          newItem.docs = 0;
          newItem.pages = 0;
        }
        filteredData[index] = newItem;
      }
      return filteredData;
    },
    chartOptions() {
      return {
        labels: this.getArray('userName'),
        tooltip: {
          style: {
            fontSize: '14px',
            backgroundColor: '#fff',
          },
          /* eslint-disable-next-line */
          custom({ series, seriesIndex, dataPointIndex, w }) {
            let total = 0;
            series.forEach((x) => {
              total += x;
            });
            const selected = series[seriesIndex];
            return `<div style="padding:10px; background: #F5F5F5; color: #4B4B4B">${w.globals.labels[seriesIndex]
            }: ${((selected / total) * 100).toFixed(1)}%</div>`;
          },
        },
        colors: this.getColors(),
        legend: {
          show: false,
        },
        chart: {
          type: 'pie',
          locales: [ru],
          defaultLocale: 'ru',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
            },
            export: {
              csv: {
                filename: 'print-documents',
                columnDelimiter: ',',
                headerCategory: 'Пользователь',
                headerValue: 'Количество',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: 'print-documents',
              },
              png: {
                filename: 'print-documents',
              },
            },
          },
        },

        dataLabels: {
          formatter(val, opts) {
            return opts.w.config.series[opts.seriesIndex];
          },
          style: {
            colors: this.getLabelColors(),
            fontSize: '16px',
          },
          dropShadow: {
            enabled: false,
          },
        },
      };
    },
    chartOptions2() {
      return {
        labels: this.getArray('userName'),
        tooltip: {
          style: {
            fontSize: '14px',
            backgroundColor: '#fff',
          },
          /* eslint-disable-next-line */
          custom({ series, seriesIndex, dataPointIndex, w }) {
            let total = 0;
            series.forEach((x) => {
              total += x;
            });
            const selected = series[seriesIndex];
            return `<div style="padding:10px; background:  #F5F5F5; color: #4B4B4B">${w.globals.labels[seriesIndex]
            }: ${((selected / total) * 100).toFixed(1)}%</div>`;
          },
        },
        colors: this.getColors(),
        legend: {
          show: false,
        },
        chart: {
          type: 'pie',
          locales: [ru],
          defaultLocale: 'ru',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
            },
            export: {
              csv: {
                filename: 'print-pages',
                columnDelimiter: ',',
                headerCategory: 'Пользователь',
                headerValue: 'Количество',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: 'print-pages',
              },
              png: {
                filename: 'print-pages',
              },
            },
          },
        },

        dataLabels: {
          formatter(val, opts) {
            return opts.w.config.series[opts.seriesIndex];
          },
          style: {
            colors: this.getLabelColors(),
            fontSize: '16px',
          },
          dropShadow: {
            enabled: false,
          },
        },
      };
    },
  },
  watch: {
    dateRange(oldValue, newValue) {
      if (
        oldValue.dateFrom.toString() !== newValue.dateFrom.toString()
        || oldValue.dateTo.toString() !== newValue.dateTo.toString()
      ) {
        this.fetch();
      }
    },
  },
  created() {
    this.$store.commit('exportData/setUserStat', { name: this.name, data: [] });
    this.fetch();
  },
  methods: {
    getColors() {
      const colors = [];
      for (let i = 0; i < this.pieData.length; i++) {
        const color = this.shadeColor(
          this.baseColors[i % this.baseColors.length],
          15 * Math.floor(i / this.baseColors.length),
        );
        colors.push(color);
      }
      return colors;
    },
    getLabelColors() {
      const colors = [];
      for (let i = 0; i < this.pieData.length; i++) {
        const color = this.shadeColor(
          this.baseColors[i % this.baseColors.length],
          -40 + 15 * Math.floor(i / this.baseColors.length),
        );
        colors.push(color);
      }
      return colors;
    },
    getColorByPosition(position) {
      return this.shadeColor(
        this.baseColors[position % this.baseColors.length],
        15 * Math.floor(position / this.baseColors.length),
      );
    },
    getArray(element) {
      return this.series.map((a) => a[element]);
    },
    fetch() {
      this.pieData = [];
      if (this.dateRange.dateFrom && this.dateRange.dateTo) {
        this.promise = getPrintUserStatistics(
          this.dateRange.dateFrom,
          this.dateRange.dateTo,
        ).then(({ data }) => {
          const tempArray = data.slice(0).filter((u) => u.userName);
          tempArray.forEach((element) => {
            element.checked = true; /* eslint-disable-line no-param-reassign */
          });
          this.pieData = tempArray;
          return { data };
        });
      }
    },
    shadeColor(color, percent) {
      let R = parseInt(color.substring(1, 3), 16);
      let G = parseInt(color.substring(3, 5), 16);
      let B = parseInt(color.substring(5, 7), 16);

      R = parseInt((R * (100 + percent)) / 100, 10);
      G = parseInt((G * (100 + percent)) / 100, 10);
      B = parseInt((B * (100 + percent)) / 100, 10);

      R = R < 255 ? R : 255;
      G = G < 255 ? G : 255;
      B = B < 255 ? B : 255;

      const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
      const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
      const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

      return `#${RR}${GG}${BB}`;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  height: 100%;
  overflow: auto;
  width: 100%;
}

.chart {
  display: inline-flex;
  flex: 1;
  height: calc(50vw - 250px);
  padding-top: 70px;
}

.svg {
  width: 100%;
}

.legend {
  display: flex;
  flex-direction: column;
  width: 218px;
  overflow: auto;
  padding: 20px 10px 10px 0px;
  background-color: #fbfbfb;
  margin: 20px;
}

.checkboxlabel {
  cursor: pointer;
}

.checkbox {
  border: 1px solid #9f9f9f;
  cursor: pointer;
  border-radius: 4px;
  width: 16px;
  height: 16px;
}

.title {
  position: absolute;
  left: 30px;
  top: 50px;
  height: 24px;
  background: $light-gray;
  font-size: 14px;
  color: #4b4b4b;
  font-weight: 400;
  padding: 5px 10px 5px 10px;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}
</style>
