<template>
  <div :class="$style.customCheckBoxContainer" @click="toggleCheckBox()">
    <input
      v-model="checkModel"
      type="checkbox"
      :class="$style.customCheckbox"
    >
    <label>
      <check-mark v-if="checkModel" />
      <div :class="$style.marker" :style="{ background: color }" />
      <div :class="$style.textContainer">
        {{ text }}
      </div>
    </label>
  </div>
</template>

<script>
import CheckMark from '../filter/check-mark.vue';

export default {
  components: {
    CheckMark,
  },
  props: ['color', 'text', 'value'],
  data: () => ({}),
  computed: {
    checkModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    toggleCheckBox() {
      this.checkModel = !this.checkModel;
    },
  },
};
</script>

<style lang="scss" module>
.marker {
  width: 30px;
  height: 10px;
  display: inline-block;
  margin: 0 14px 6px 14px;
}
.textContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 94px;
  line-height: 22px;
}
.customCheckBoxContainer {
  height: 30px;
  padding: 7px 0 7px 10px;
  margin-left: 10px;
  .customCheckbox {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  &:hover {
    background: $light-gray;
  }
  input:checked + label > svg {
    animation: draw-checkbox ease-in-out 0.2s forwards;
  }
  label:active::after {
    background-color: #fbfbfb;
  }
  label {
    color: black;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    position: relative;
    display: block;
    &:after {
      content: '';
      height: 14px;
      width: 14px;
      float: left;
      border: 1px solid #9f9f9f;
      border-radius: 4px;
      transition: 0.15s all ease-out;
      background-color: white;
    }
  }
  input:checked + label:after {
    background-color: $blue;
    border: 1px solid $blue;
  }
  svg {
    position: absolute;
    left: 4px;
    top: 4px;
    stroke-dasharray: 33;
  }
  @keyframes draw-checkbox {
    0% {
      stroke-dashoffset: 33;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
}
</style>
